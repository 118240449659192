import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import styles from "./projectList.module.scss"
import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"

export const filterSVG = () => {
  return (
    <filter
      id="dark"
      x="-10%"
      y="-10%"
      width="120%"
      height="120%"
      filterUnits="objectBoundingBox"
      primitiveUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feColorMatrix
        type="matrix"
        values="0 0 1 0 0
            0 0 1 0 0
            0 0 1 0 0
            0 0 0 1 0"
        in="SourceGraphic"
        result="colormatrix"
      />
      <feComponentTransfer in="colormatrix" result="componentTransfer">
        <feFuncR type="table" tableValues="0.29 0.15 0.97" />
        <feFuncG type="table" tableValues="0.04 0.39 0.93" />
        <feFuncB type="table" tableValues="0.32 0.52 0.73" />
        <feFuncA type="table" tableValues="0 1" />
      </feComponentTransfer>
      <feBlend
        mode="normal"
        in="componentTransfer"
        in2="SourceGraphic"
        result="blend"
      />
    </filter>
  )
}

const ProjectList = () => {
  const projectData = useStaticQuery(graphql`
    query {
      allContentfulPortfolioProject(
        sort: { fields: contentfulid, order: ASC }
      ) {
        edges {
          node {
            title
            id
            description {
              json
            }
            screenshot {
              file {
                url
              }
            }
            contentfulid
            slug
            stack
            video {
              file {
                url
              }
            }
            category
          }
        }
      }
    }
  `)
  return (
    <>
      <motion.div
        className={styles.mobileShow}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className={styles.introContent}>
          Pia Kim
          <br />
          <span className="blue">front-end developer</span>
        </div>
      </motion.div>
      <motion.div
        className={styles.projects}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {/* <div className={styles.subTitle}>
          <h3 className={styles.subheading}>Portfolio—</h3>
        </div> */}
        <ul>
          {projectData.allContentfulPortfolioProject.edges.map(
            (list, index) => (
              <List
                key={list.node.contentfulid}
                title={list.node.title}
                src={index}
                slug={list.node.slug}
                video={list.node.video.file.url}
                poster={list.node.screenshot.file.url}
                category={list.node.category}
                id={index + 1}
                // x={x}
                // y={y}
                // offset={list.offset}
                // thumbnailPosition={list.thumbnailPosition}
              />
            )
          )}
        </ul>
      </motion.div>
    </>
  )
}

const List = ({
  title,
  id,
  src,
  x,
  y,
  offset,
  thumbnailPosition,
  video,
  poster,
  slug,
  category,
}) => {
  const list = useRef()
  const [hoverState, setHoverState] = useState(false)
  const [state, setState] = useState({
    top: 0,
    left: 0,
  })

  useEffect(() => {
    setState({
      top: list.current.getBoundingClientRect().top,
      left: list.current.getBoundingClientRect().left,
    })
  }, [hoverState])

  return (
    <>
      <li className={title} ref={list}>
        <Link to={`/project/${slug}`}>
          <div className={styles.wrapper}>
            <div className={styles.numberWrapper}>{id}</div>
            <motion.div
              className={styles.title}
              onHoverStart={() => setHoverState(true)}
              onHoverEnd={() => setHoverState(false)}
            >
              <h2>
                <div className={styles.projectName}>{title}</div>
              </h2>
            </motion.div>
            <div className={styles.thumbnail}>
              {/* <Image src={src} /> */}
              {/* <img src={src} /> */}
              <video
                width="100%"
                autoPlay
                muted
                loop
                playsInline
                poster={poster}
              >
                <source src={video} type="video/mp4"></source>
              </video>
            </div>
            <div className={styles.imgTitle}>{category}</div>
            {/* <motion.div
              initial={{ opacity: 0 }}
              className={styles.floatingImage}
          
            >
              <div>
                {" "}
                <video
                  width="100%"
                  autoPlay
                  muted
                  loop
                  playsinline
                  poster={poster}
                >
                  <source src={video} type="video/mp4"></source>
                </video>
              </div>
            </motion.div> */}
          </div>
        </Link>
      </li>
    </>
  )
}

export default ProjectList
