import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import "./index.scss"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Contact from "../components/contact"
import SEO from "../components/seo"
import ProjectList from "../components/projectList"

import useMousePosition from "../hooks/useMousePosition"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPortfolioProject(
        sort: { fields: contentfulid, order: ASC }
      ) {
        edges {
          node {
            title
            description {
              json
            }
            screenshot {
              file {
                url
              }
            }
            contentfulid
            slug
            stack
            video {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

     //get x and y mouse coordinates
  // const { x, y } = useMousePosition()


  // useEffect(() => {
  //   window.addEventListener("load", logMousePosition)
  //   console.log("Created")
  //   return () => {
  //     console.log("Cleaned up")
  //     window.removeEventListener("load", logMousePosition)
  //   }
  // }, [])

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  return (
    <>
      <Layout>
        <Head title="Home" />
        <ProjectList/>
        {/* <div className="hero">
          <h1 className="fade-in">Building</h1>
          <h1 className="fade-in">digital experiences</h1>
          <span>web developer</span> */}

        {/* <svg className="fade-in" viewBox="0 0 900 460">
            <text
              className="txtTitle"
              // font-size="1.3em"
              letter-spacing="0em"
              // text-anchor="middle"
              // x="10%"
              y="45%"
              dy="50px"
              class="text"
            >
              Building digital experiences
            </text>
            <text
              fill="#c4c4c4"
              className="txtTitle subTitle"
              // font-size=".5em"
              // text-anchor="middle"
              x="0"
              y="52%"
              dy="50px"
              class="text sub-title"
            >
              Web Developer
            </text>
          </svg> */}
        {/* </div> */}
  
      </Layout>
    </>
  )
}

export default IndexPage
